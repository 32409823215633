<template>
  <div
    :class="wrapperClasses"
    class="vary-autocomplete"
  >
    <div
      :class="[{ 'vary-autocomplete-selected': value }, inputWrapperClasses]"
      class="vary-autocomplete-input-group"
    >
      <input
        v-model="postalCode"
        :class="inputClasses"
        :placeholder="placeholder"
        :disabled="disabled"
        type="search"
        class="vary-autocomplete-input"
        @blur="blur"
        @focus="focus"
        @input="inputChange"
        @keydown.enter.prevent="keyEnter"
        @keydown.up.prevent="keyUp"
        @keydown.down.prevent="keyDown"
      />
      <p class="postal-name">
        {{ postalName }}
      </p>
    </div>
    <div
      v-if="loading"
      name="loading"
      class="vary-autocomplete-loading"
    >
      Loading...
    </div>
    <div
      v-else-if="showList"
      :class="suggestionListClasses"
      name="suggestionList"
      class="vary-autocomplete-list"
    >
      <div
        v-for="(item, i) in sortedItems"
        :key="i"
        :class="[{ 'vary-autocomplete-item-active': i === cursor }, suggestionItemWrapperClasses]"
        class="vary-autocomplete-list-item"
        @click="selectItem(item)"
        @mouseover="cursor = i"
      >
        <div :is="itemTemplate" :class="suggestionItemClasses" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Autocomplete',
    props: {
      itemTemplate: { type: Object, required: true },
      minLen: { type: Number, default: 2 },
      value: { type: [Object, String, Number], default: null },
      items: { type: Array, default: () => [] },
      disabled: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
      placeholder: { type: String, default: '' },
      inputClasses: { type: String, default: '' },
      wrapperClasses: { type: String, default: '' },
      inputWrapperClasses: { type: String, default: '' },
      suggestionListClasses: { type: String, default: '' },
      suggestionItemWrapperClasses: { type: String, default: '' },
      suggestionItemClasses: { type: String, default: '' }
    },
    data () {
      return {
        postalCode: '',
        postalName: '',
        showList: false,
        cursor: 0
      }
    },
    computed: {
      sortedItems () {
        return this.items.reduce((prv, crr) => {
          prv.push(crr)
          return prv
        }, [])
      }
    },
    watch: {
      value: {
        handler (value) {
          if (!value) {
            return
          }
          this.postalCode = value.postal
          this.postalName = value.name
        },
        deep: true
      },
      'items.length' () {
        // Items might be changed from Promise after searching
        // So we need the check if we should show the suggestion list
        this.showList = this.isAbleToShowList()
      }
    },
    created () {
      this.checkMissingProps()
    },
    mounted () {
      if (this.value) {
        this.postalCode = this.value.postal
        this.postalName = this.value.name
      }
    },
    methods: {
      inputChange () {
        this.showList = this.isAbleToShowList()
        this.cursor = 0
        this.$emit('onInputChange', this.postalCode)
      },
      isAbleToShowList () {
        return (this.postalCode || '').length >= this.minLen && this.items.length > 0
      },
      checkMissingProps () {
        if (!this.itemTemplate) {
          console.warn('You need to pass `template` as the suggestion list item template')
        }
      },
      focus () {
        this.$emit('focus', this.postalCode)
        this.showList = this.isAbleToShowList()
      },
      blur () {
        this.$emit('blur', this.postalCode)
        // set timeout for the click event to work
        setTimeout(() => {
          this.showList = false
        }, 200)
      },
      selectItem (item) {
        if (item) {
          this.postalCode = item.postal
          this.postalName = item.name
          this.$emit('onItemSelected', item)
        }
        this.$emit('input', item)
      },
      keyUp () {
        this.$emit('keyUp', this.postalCode)
        if (this.cursor > 0) {
          this.cursor -= 1
        }
      },
      keyDown () {
        this.$emit('keyDown', this.postalCode)
        if (this.cursor < this.items.length - 1) {
          this.cursor += 1
        }
      },
      keyEnter () {
        if (this.showList && this.items[this.cursor]) {
          this.selectItem(this.items[this.cursor])
          this.showList = false
        }
        this.$emit('onEnter', this.items[this.cursor])
      }
    }
  }
</script>

<style lang="scss">
  .vary-autocomplete {
    position: relative;

    .vary-autocomplete-list {
      position: absolute;
      width: 100%;
      text-align: left;
      border: none;
      border-top: none;
      max-height: 400px;
      overflow-y: auto;
      border-bottom: 1px solid #023d7b;
      z-index: 2;

      .vary-autocomplete-list-item {
        cursor: pointer;
        background-color: #fff;
        padding: 10px;
        border-left: 4px solid #551A41;
        border-right: 1px solid #551A41;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: #eee;
        }

        &.vary-autocomplete-item-active {
          background-color: #f3f6fa;
        }
      }
    }

    .vary-autocomplete-input-group {
      display: flex;
      align-items: center;

      .postal-name {
        margin-left: 16px;
        margin-bottom: 0;
        font-weight: 500;
      }

      .vary-autocomplete-input {
        flex: 0 0 20%;
      }
    }
  }
</style>
